var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"rows":"12","md":"3"}},[_c('h3',[_vm._v("Logo")]),(_vm.currentClient.logo != '' && _vm.currentClient.logo != null)?_c('v-badge',{staticClass:"u-cursor-pointer",attrs:{"color":"primary","content":"X","overlap":""},nativeOn:{"click":function($event){return _vm.clearFileField('logo')}}},[_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('logo')}}},[(_vm.isLoadingLogo)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):(_vm.logo)?_c('img',{staticClass:"c-client-logo",attrs:{"src":_vm.logo,"alt":"Logo"}}):_c('img',{staticClass:"c-client-logo",attrs:{"src":("https://ehome-media.s3.amazonaws.com/" + (_vm.currentClient.logo)),"alt":"Logo"}})],1)],1):_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('logo')}}},[_c('v-icon',[_vm._v("mdi-camera")])],1),_c('input',{ref:"uploader_logo",staticClass:"d-none",attrs:{"id":"logo","type":"file","accept":"image/*"},on:{"click":function($event){_vm.selected = 'logo'},"change":_vm.onFileChanged}})],1),_c('v-col',{attrs:{"rows":"12","md":"3"}},[_c('h3',[_vm._v("Logo Horizontal")]),(
              _vm.currentClient.logo_horizontal != '' &&
              _vm.currentClient.logo_horizontal != null
            )?_c('v-badge',{staticClass:"u-cursor-pointer",attrs:{"color":"primary","content":"X","overlap":""},nativeOn:{"click":function($event){return _vm.clearFileField('logo_horizontal')}}},[_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('logo_horizontal')}}},[(_vm.isLoadingLogoHorizontal)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):(_vm.logo_horizontal)?_c('img',{staticClass:"c-client-logo",attrs:{"src":_vm.logo_horizontal,"alt":"Logo Horizontal"}}):_c('img',{staticClass:"c-client-logo",attrs:{"src":("https://ehome-media.s3.amazonaws.com/" + (_vm.currentClient.logo_horizontal)),"alt":"Logo Horizontal"}})],1)],1):_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('logo_horizontal')}}},[_c('v-icon',[_vm._v("mdi-camera")])],1),_c('input',{ref:"uploader_logo_horizontal",staticClass:"d-none",attrs:{"id":"logo_horizontal","type":"file","accept":"image/*"},on:{"click":function($event){_vm.selected = 'logo_horizontal'},"change":_vm.onFileChanged}})],1),_c('v-col',{attrs:{"rows":"12","md":"3"}},[_c('h3',[_vm._v("Logo Branca")]),(
              _vm.currentClient.logo_white != '' &&
              _vm.currentClient.logo_white != null
            )?_c('v-badge',{staticClass:"u-cursor-pointer",attrs:{"color":"primary","content":"X","overlap":""},nativeOn:{"click":function($event){return _vm.clearFileField('logo_white')}}},[_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('logo_white')}}},[(_vm.isLoadingLogoWhite)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):(_vm.logo_white)?_c('img',{staticClass:"c-client-logo",attrs:{"src":_vm.logo_white,"alt":"Logo Branca"}}):_c('img',{staticClass:"c-client-logo",attrs:{"src":("https://ehome-media.s3.amazonaws.com/" + (_vm.currentClient.logo_white)),"alt":"Logo Branca"}})],1)],1):_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('logo_white')}}},[_c('v-icon',[_vm._v("mdi-camera")])],1),_c('input',{ref:"uploader_logo_white",staticClass:"d-none",attrs:{"id":"logo_white","type":"file","accept":"image/*"},on:{"click":function($event){_vm.selected = 'logo_white'},"change":_vm.onFileChanged}})],1),_c('v-col',{attrs:{"rows":"12","md":"3"}},[_c('h3',[_vm._v("Logo Preta")]),(
              _vm.currentClient.logo_black != '' &&
              _vm.currentClient.logo_black != null
            )?_c('v-badge',{staticClass:"u-cursor-pointer",attrs:{"color":"primary","content":"X","overlap":""},nativeOn:{"click":function($event){return _vm.clearFileField('logo_black')}}},[_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('logo_black')}}},[(_vm.isLoadingLogoBlack)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):(_vm.logo_black)?_c('img',{staticClass:"c-client-logo",attrs:{"src":_vm.logo_black,"alt":"Logo Preta"}}):_c('img',{staticClass:"c-client-logo",attrs:{"src":("https://ehome-media.s3.amazonaws.com/" + (_vm.currentClient.logo_black)),"alt":"Logo Preta"}})],1)],1):_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('logo_black')}}},[_c('v-icon',[_vm._v("mdi-camera")])],1),_c('input',{ref:"uploader_logo_black",staticClass:"d-none",attrs:{"id":"logo_black","type":"file","accept":"image/*"},on:{"click":function($event){_vm.selected = 'logo_black'},"change":_vm.onFileChanged}})],1),_c('v-col',{attrs:{"rows":"12","md":"3"}},[_c('h3',[_vm._v("Marca d' água")]),(
              _vm.currentClient.water_mark != '' &&
              _vm.currentClient.water_mark != null
            )?_c('v-badge',{staticClass:"u-cursor-pointer",attrs:{"color":"primary","content":"X","overlap":""},nativeOn:{"click":function($event){return _vm.clearFileField('water_mark')}}},[_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('water_mark')}}},[(_vm.isLoadingWaterMark)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):(_vm.water_mark)?_c('img',{staticClass:"c-client-logo",attrs:{"src":_vm.water_mark,"alt":"Marca d'água"}}):_c('img',{staticClass:"c-client-logo",attrs:{"src":("https://ehome-media.s3.amazonaws.com/" + (_vm.currentClient.water_mark)),"alt":"Marca d'água"}})],1)],1):_c('v-avatar',{staticClass:"u-cursor-pointer",attrs:{"width":"250px","height":"200px","rounded":"","color":"grey lighten-4 elevation-2"},on:{"click":function($event){return _vm.onButtonClick('water_mark')}}},[_c('v-icon',[_vm._v("mdi-camera")])],1),_c('input',{ref:"uploader_water_mark",staticClass:"d-none",attrs:{"id":"water_mark","type":"file","accept":"image/*"},on:{"click":function($event){_vm.selected = 'water_mark'},"change":_vm.onFileChanged}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }