<template>
  <v-card flat>
    <v-card-text>
      <v-form>
        <v-row dense no-gutters>
          <v-col rows="12" md="3">
            <h3>Logo</h3>
            <v-badge
              class="u-cursor-pointer"
              v-if="currentClient.logo != '' && currentClient.logo != null"
              color="primary"
              content="X"
              overlap
              @click.native="clearFileField('logo')"
            >
              <v-avatar
                class="u-cursor-pointer"
                width="250px"
                height="200px"
                rounded
                color="grey lighten-4 elevation-2"
                @click="onButtonClick('logo')"
              >
                <v-progress-circular
                  v-if="isLoadingLogo"
                  indeterminate
                  color="primary"
                />
                <img
                  v-else-if="logo"
                  :src="logo"
                  class="c-client-logo"
                  alt="Logo"
                />
                <img
                  v-else
                  :src="`https://encode-index.s3.amazonaws.com/${currentClient.logo}`"
                  class="c-client-logo"
                  alt="Logo"
                />
              </v-avatar>
            </v-badge>

            <v-avatar
              v-else
              class="u-cursor-pointer"
              width="250px"
              height="200px"
              rounded
              color="grey lighten-4 elevation-2"
              @click="onButtonClick('logo')"
            >
              <v-icon>mdi-camera</v-icon>
            </v-avatar>
            <input
              ref="uploader_logo"
              id="logo"
              class="d-none"
              type="file"
              accept="image/*"
              @click="selected = 'logo'"
              @change="onFileChanged"
            />
          </v-col>

          <v-col rows="12" md="3">
            <h3>Logo Horizontal</h3>
            <v-badge
              class="u-cursor-pointer"
              v-if="
                currentClient.logo_horizontal != '' &&
                currentClient.logo_horizontal != null
              "
              color="primary"
              content="X"
              overlap
              @click.native="clearFileField('logo_horizontal')"
            >
              <v-avatar
                class="u-cursor-pointer"
                width="250px"
                height="200px"
                rounded
                color="grey lighten-4 elevation-2"
                @click="onButtonClick('logo_horizontal')"
              >
                <v-progress-circular
                  v-if="isLoadingLogoHorizontal"
                  indeterminate
                  color="primary"
                />
                <img
                  v-else-if="logo_horizontal"
                  :src="logo_horizontal"
                  class="c-client-logo"
                  alt="Logo Horizontal"
                />
                <img
                  v-else
                  :src="`https://encode-index.s3.amazonaws.com/${currentClient.logo_horizontal}`"
                  class="c-client-logo"
                  alt="Logo Horizontal"
                />
              </v-avatar>
            </v-badge>

            <v-avatar
              v-else
              class="u-cursor-pointer"
              width="250px"
              height="200px"
              rounded
              color="grey lighten-4 elevation-2"
              @click="onButtonClick('logo_horizontal')"
            >
              <v-icon>mdi-camera</v-icon>
            </v-avatar>
            <input
              ref="uploader_logo_horizontal"
              id="logo_horizontal"
              class="d-none"
              type="file"
              accept="image/*"
              @click="selected = 'logo_horizontal'"
              @change="onFileChanged"
            />
          </v-col>

          <v-col rows="12" md="3">
            <h3>Logo Branca</h3>
            <v-badge
              class="u-cursor-pointer"
              v-if="
                currentClient.logo_white != '' &&
                currentClient.logo_white != null
              "
              color="primary"
              content="X"
              overlap
              @click.native="clearFileField('logo_white')"
            >
              <v-avatar
                class="u-cursor-pointer"
                width="250px"
                height="200px"
                rounded
                color="grey lighten-4 elevation-2"
                @click="onButtonClick('logo_white')"
              >
                <v-progress-circular
                  v-if="isLoadingLogoWhite"
                  indeterminate
                  color="primary"
                />
                <img
                  v-else-if="logo_white"
                  :src="logo_white"
                  class="c-client-logo"
                  alt="Logo Branca"
                />
                <img
                  v-else
                  :src="`https://encode-index.s3.amazonaws.com/${currentClient.logo_white}`"
                  class="c-client-logo"
                  alt="Logo Branca"
                />
              </v-avatar>
            </v-badge>

            <v-avatar
              v-else
              class="u-cursor-pointer"
              width="250px"
              height="200px"
              rounded
              color="grey lighten-4 elevation-2"
              @click="onButtonClick('logo_white')"
            >
              <v-icon>mdi-camera</v-icon>
            </v-avatar>
            <input
              ref="uploader_logo_white"
              id="logo_white"
              class="d-none"
              type="file"
              accept="image/*"
              @click="selected = 'logo_white'"
              @change="onFileChanged"
            />
          </v-col>

          <v-col rows="12" md="3">
            <h3>Logo Preta</h3>
            <v-badge
              class="u-cursor-pointer"
              v-if="
                currentClient.logo_black != '' &&
                currentClient.logo_black != null
              "
              color="primary"
              content="X"
              overlap
              @click.native="clearFileField('logo_black')"
            >
              <v-avatar
                class="u-cursor-pointer"
                width="250px"
                height="200px"
                rounded
                color="grey lighten-4 elevation-2"
                @click="onButtonClick('logo_black')"
              >
                <v-progress-circular
                  v-if="isLoadingLogoBlack"
                  indeterminate
                  color="primary"
                />
                <img
                  v-else-if="logo_black"
                  :src="logo_black"
                  class="c-client-logo"
                  alt="Logo Preta"
                />
                <img
                  v-else
                  :src="`https://encode-index.s3.amazonaws.com/${currentClient.logo_black}`"
                  class="c-client-logo"
                  alt="Logo Preta"
                />
              </v-avatar>
            </v-badge>

            <v-avatar
              v-else
              class="u-cursor-pointer"
              width="250px"
              height="200px"
              rounded
              color="grey lighten-4 elevation-2"
              @click="onButtonClick('logo_black')"
            >
              <v-icon>mdi-camera</v-icon>
            </v-avatar>
            <input
              ref="uploader_logo_black"
              id="logo_black"
              class="d-none"
              type="file"
              accept="image/*"
              @click="selected = 'logo_black'"
              @change="onFileChanged"
            />
          </v-col>

          <v-col rows="12" md="3">
            <h3>Marca d' água</h3>
            <v-badge
              class="u-cursor-pointer"
              v-if="
                currentClient.water_mark != '' &&
                currentClient.water_mark != null
              "
              color="primary"
              content="X"
              overlap
              @click.native="clearFileField('water_mark')"
            >
              <v-avatar
                class="u-cursor-pointer"
                width="250px"
                height="200px"
                rounded
                color="grey lighten-4 elevation-2"
                @click="onButtonClick('water_mark')"
              >
                <v-progress-circular
                  v-if="isLoadingWaterMark"
                  indeterminate
                  color="primary"
                />
                <img
                  v-else-if="water_mark"
                  :src="water_mark"
                  class="c-client-logo"
                  alt="Marca d'água"
                />
                <img
                  v-else
                  :src="`https://encode-index.s3.amazonaws.com/${currentClient.water_mark}`"
                  class="c-client-logo"
                  alt="Marca d'água"
                />
              </v-avatar>
            </v-badge>

            <v-avatar
              v-else
              class="u-cursor-pointer"
              width="250px"
              height="200px"
              rounded
              color="grey lighten-4 elevation-2"
              @click="onButtonClick('water_mark')"
            >
              <v-icon>mdi-camera</v-icon>
            </v-avatar>
            <input
              ref="uploader_water_mark"
              id="water_mark"
              class="d-none"
              type="file"
              accept="image/*"
              @click="selected = 'water_mark'"
              @change="onFileChanged"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    isLoadingLogo: Boolean,
    isLoadingLogoHorizontal: Boolean,
    isLoadingLogoWhite: Boolean,
    isLoadingLogoBlack: Boolean,
    isLoadingWaterMark: Boolean,
    currentClient: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    logo: "",
    logo_horizontal: "",
    logo_white: "",
    logo_black: "",
    water_mark: "",
    selected: null,
  }),
  methods: {
    update(img) {
      switch (img) {
        case "logo":
          this.$emit("updateLogo");
          break;
        case "logo_horizontal":
          this.$emit("updateLogoHorizontal");
          break;
        case "logo_white":
          this.$emit("updateLogoWhite");
          break;
        case "logo_black":
          this.$emit("updateLogoBlack");
          break;
        case "water_mark":
          this.$emit("updateWaterMark");
          break;
      }
    },
    onButtonClick(img) {
      switch (img) {
        case "logo":
          this.$refs.uploader_logo.click();
          break;
        case "logo_horizontal":
          this.$refs.uploader_logo_horizontal.click();
          break;
        case "logo_white":
          this.$refs.uploader_logo_white.click();
          break;
        case "logo_black":
          this.$refs.uploader_logo_black.click();
          break;
        case "water_mark":
          this.$refs.uploader_water_mark.click();
          break;
      }
    },
    onFileChanged(e) {
      switch (this.selected) {
        case "logo":
          this.currentClient.logo = e.target.files[0];
          this.logo = URL.createObjectURL(e.target.files[0]);
          break;
        case "logo_horizontal":
          this.currentClient.logo_horizontal = e.target.files[0];
          this.logo_horizontal = URL.createObjectURL(e.target.files[0]);
          break;
        case "logo_white":
          this.currentClient.logo_white = e.target.files[0];
          this.logo_white = URL.createObjectURL(e.target.files[0]);
          break;
        case "logo_black":
          this.currentClient.logo_black = e.target.files[0];
          this.logo_black = URL.createObjectURL(e.target.files[0]);
          break;
        case "water_mark":
          this.currentClient.water_mark = e.target.files[0];
          this.water_mark = URL.createObjectURL(e.target.files[0]);
          break;
      }

      this.update(this.selected);
    },

    clearFileField(img) {
      switch (img) {
        case "logo":
          this.currentClient.logo = null;
          break;
        case "logo_horizontal":
          this.currentClient.logo_horizontal = null;
          break;
        case "logo_white":
          this.currentClient.logo_white = null;
          break;
        case "logo_black":
          this.currentClient.logo_black = null;
          break;
        case "water_mark":
          this.currentClient.water_mark = null;
          break;
      }
    },
  },
};
</script>

<style>
.c-client-logo {
  object-fit: contain;
}
.u-cursor-pointer {
  cursor: pointer;
}
</style>